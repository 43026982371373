var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":"","id":"change-password"}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-6 pt-5 d-flex"},[_c('h2',[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")])],1),_c('h2',{staticClass:"pt-1"},[_vm._v("Change password")]),(_vm.code == 200)?_c('div',{staticClass:"success"},[_c('v-alert',{staticStyle:{"font-size":"14px","padding":"12px"},attrs:{"value":_vm.checkSuccess,"transition":"scale-transition","type":"success"}},[_vm._v(" Reset password successfully! ")])],1):_vm._e()]),_c('div',{staticClass:"change-password-wrapper"},[_c('div',{staticClass:"change-password-inner"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_c('validation-provider',{attrs:{"name":"oldPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Old password","append-icon":_vm.passTypeOldPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passTypeOldPass ? 'text' : 'password',"error-messages":errors,"required":"","outlined":""},on:{"click:append":function($event){_vm.passTypeOldPass = !_vm.passTypeOldPass},"keydown":function($event){_vm.loginErrorMessage = false}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}])}),_c('validation-provider',{attrs:{"name":"newPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"New password","append-icon":_vm.passTypeNewPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passTypeNewPass ? 'text' : 'password',"error-messages":errors,"required":"","outlined":""},on:{"click:append":function($event){_vm.passTypeNewPass = !_vm.passTypeNewPass},"keydown":function($event){_vm.loginErrorMessage = false}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}])}),_c('validation-provider',{attrs:{"name":"confirmNewPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm new password","append-icon":_vm.passTypeReNewPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passTypeReNewPass ? 'text' : 'password',"error-messages":errors,"required":"","outlined":""},on:{"click:append":function($event){_vm.passTypeReNewPass = !_vm.passTypeReNewPass},"keydown":function($event){_vm.loginErrorMessage = false}},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})]}}])})],1),(_vm.code != 200 && _vm.code)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Change password failed! ")]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.back()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"secondary","type":"submit","disabled":!_vm.oldPassword ||
                    !_vm.newPassword ||
                    !_vm.confirmNewPassword ||
                    _vm.newPassword != _vm.confirmNewPassword},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Save")])],1)])],1)],1)])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto pa-10"},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"pl-4 pr-4 mr-3 mb-5",staticStyle:{"background":"white"},attrs:{"elevation":"0","height":"36","loading":_vm.dialog}})],1),_c('div',{staticClass:"text-center"},[_c('b',{staticStyle:{"font-size":"1.5rem"}},[_vm._v("Saving!")]),_c('br'),_c('span',[_vm._v("Please wait...")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }